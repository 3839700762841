import React from "react"
import { Row, Col, Container, Carousel } from "react-bootstrap"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import CatImage1 from "../components/CatImage1"
import CatImage2 from "../components/CatImage2"
import CatImage3 from "../components/CatImage3"
import CatImage4 from "../components/CatImage4"
import CatImage5 from "../components/CatImage5"
import CatImage6 from "../components/CatImage6"
import CatImage7 from "../components/CatImage7"
import CatImage8 from "../components/CatImage8"
import CatImage9 from "../components/CatImage9"
import CatImage10 from "../components/CatImage10"
import CatImage11 from "../components/CatImage11"
import CatImage12 from "../components/CatImage12"
import CatImage13 from "../components/CatImage13"
import CatImage14 from "../components/CatImage14"
import CatImage15 from "../components/CatImage15"
import CatImage16 from "../components/CatImage16"
import CatImage17 from "../components/CatImage17"
import CatImage18 from "../components/CatImage18"
import CatImage19 from "../components/CatImage19"
import CatImage20 from "../components/CatImage20"
import CatImage21 from "../components/CatImage21"
import CatImage22 from "../components/CatImage22"
import CatImage23 from "../components/CatImage23"

const IndexPage = () => (
  <Layout pageInfo={{ pageName: "index" }} style={{
        background: `#5E6D7A`,
      }}>
    <SEO title="Home" keywords={[`gatsby`, `react`, `bootstrap`]} />
    <Container className="text-center">
      <Row>
        <Col>
            <p className="content-text"><br/>&quot;In 20 Jahren wirst du mehr bedauern, was du nicht getan hast als das, was du getan hast.<br/>Also löse die Leinen, verlasse deinen sicheren Hafen.<br/> Lass den Wind in deine Segel wehen.<br/><strong>Erforsche! Träume! Entdecke!</strong>&quot; <br/> (Mark Twain)</p>
        </Col>
      </Row>
      <Row>
        <Col>
          <Carousel className="border">
            <Carousel.Item>
              <CatImage1 />
              <Carousel.Caption>
              <h3>Boot</h3>
              <p>Unser Katamaran Jolie vom Typ Bali 4.1.</p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <CatImage2 />
              <Carousel.Caption>
                <h3>Reise</h3>
                <p>Entdecke mit uns das Mittelmeer und seine Inseln.</p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <CatImage3 />
              <Carousel.Caption>
                <h3>Delphine</h3>
                <p>Erlebe Delphine zum Greifen nah.</p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <CatImage13 />
              <Carousel.Caption>
                <h3>Sport</h3>
                <p>Geh vom Boot Windsurfen, Kitesurfen, Foilsurfen, ...</p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <CatImage7 />
              <Carousel.Caption>
                <h3>Reise</h3>
                <p>Entdecke mit uns das Mittelmeer und seine Inseln.</p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <CatImage8 />
              <Carousel.Caption>
                <h3>Abenteuer</h3>
                <p>Erforsche Steilküsten und Grotten mit dem SUP.</p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <CatImage17 />
              <Carousel.Caption>
                <h3>Abenteuer</h3>
                <p>Entdecke wunderschöne Buchten und Ankerplätze.</p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <CatImage4 />
              <Carousel.Caption>
                <h3>Delphine</h3>
                <p>Erlebe Delphine zum Greifen nah.</p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <CatImage9 />
              <Carousel.Caption>
                <h3>Abenteuer</h3>
                <p>Erforsche Steilküsten und Grotten mit dem SUP.</p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <CatImage10 />
              <Carousel.Caption>
                <h3>Reise</h3>
                <p>Entdecke mit uns das Mittelmeer und seine Inseln.</p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <CatImage5 />
              <Carousel.Caption>
                <h3>Delphine</h3>
                <p>Erlebe Delphine zum Greifen nah.</p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <CatImage11 />
              <Carousel.Caption>
                <h3>Reise</h3>
                <p>Verweile und übernachte in schönen Buchten.</p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <CatImage12 />
              <Carousel.Caption>
                <h3>Sport</h3>
                <p>Geh vom Katamaran Windsurfen, Kitesurfen, Foilsurfen, ...</p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <CatImage6 />
              <Carousel.Caption>
                <h3>Delphine</h3>
                <p>Erlebe Delphine zum Greifen nah.</p>
              </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item>
              <CatImage14 />
              <Carousel.Caption>
                <h3>Reise</h3>
                <p>Verweile und übernachte in schönen Buchten.</p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <CatImage15 />
              <Carousel.Caption>
                <h3>Reise</h3>
                <p>Genieße das Leben an Bord und in malerischen Dörfern.</p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <CatImage16 />
              <Carousel.Caption>
                <h3>Reise</h3>
                <p>Verweile und übernachte in schönen Buchten.</p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <CatImage18 />
              <Carousel.Caption>
                <h3>Abenteuer</h3>
                <p>Erforsche Steilküsten und Grotten mit dem SUP.</p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <CatImage19 />
              <Carousel.Caption>
                <h3>Reise</h3>
                <p>Entdecke mit uns das Mittelmeer und seine Inseln.</p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <CatImage20 />
              <Carousel.Caption>
                <h3>Reise</h3>
                <p>Entdecke mit uns das Mittelmeer und seine Inseln.</p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <CatImage21 />
              <Carousel.Caption>
                <h3>Reise</h3>
                <p>Entdecke mit uns das Mittelmeer und seine Inseln.</p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <CatImage22 />
              <Carousel.Caption>
                <h3>Reise</h3>
                <p>Entdecke mit uns das Mittelmeer und seine Inseln.</p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <CatImage23 />
              <Carousel.Caption>
                <h3>Reise</h3>
                <p>Entdecke mit uns das Mittelmeer und seine Inseln.</p>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </Col>
      </Row>
      <Row>
        <Col>
            <p align="left" className="content-text"><br/>Ein Segelurlaub ist ein ganz besonderes Abenteuer.</p>
            <p align="left" className="content-text">Wir bieten Reisen für Menschen an, die flexibel im Geist sind, inspiriert und begeistert von der Schönheit der Natur.
            Menschen, die Lust und Freude daran haben, selbst zu gestalten und das Leben auf und am Wasser lieben.</p>
            <p align="left" className="content-text"><strong>Erkennst Du Dich wieder?</strong> <br/></p>
            <p align="left" className="content-text">Dann informiere Dich näher über unser Angebot &nbsp; &nbsp;
                <Link to="/charter" className="action-button">Kojencharter</Link><br/>
            </p>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default IndexPage
